import { navigate } from 'gatsby-link';
import React from 'react'
import dateToStr from '../../../common/dateToStr';
import Body3 from '../../tags/body3';

const BoardListItem = ({order, title, date, idx}) => {
  
  const onClick = () => {navigate(`/board/view?idx=${idx}`)};
  return (
    <li className="w-full" onClick={onClick}>
      <Title order={order} title={title} date={date}/>
      {/* <Content show={show} content={content}/> */}
    </li>
  )
}
const Title = ({order=0, title="", date = new Date()}) => {

  return (
    <div className="flex justify-between items-center py-[13px] border-b border-gray-030" role="button">
      <span className="flex-1 text-[20px] font-bold leading-9 text-gray-080 truncate">{`${order}. ${title}`}</span>
      <span className="pl-3 text-xs leading-[26px] font-light text-gray-060">{dateToStr(date)}</span>
    </div>  
  )
}
const Content = ({show, content=""}) => (
  <div className={`w-full ${show ? 'max-h-fit border-b border-gray-030' : 'max-h-0 overflow-hidden'}`}>
    <Body3 className="max-w-full p-7 px-4 whitespace-pre-line break-words">
      {content}
    </Body3>
  </div>
)
export default BoardListItem;