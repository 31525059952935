import React from 'react'
import Body2 from '../../tags/body2';
import BoardListItem from './listItem';
import BoardItems from '../../../data/board.json';
import BoardTitle from './title';

const BoardList = () => {
  
  return (
    <BoardLayout>
      <BoardTitle />
      {
        BoardItems.length > 0
        ? <>
            <BoardContent />
            <Paginations />
          </>
        : <NothingUploaded />
      }
    </BoardLayout>
  )
}
const BoardLayout = ({children}) => (
  <article className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x mb-[72px] flex-1 flex flex-col justify-items-stretch overflow-auto">
    {children}
  </article>
)
const BoardContent = () => (
  <ul className="relative flex-1 w-full h-full">
    {
      BoardItems.map((item, idx) => <BoardListItem key={idx} order={BoardItems.length - idx} idx={idx} {...item} />)
    }
  </ul>
)
const Paginations = () => (
  <div className="mt-10 w-full">
    <div className="w-fit mx-auto text-gray-600">
      1
    </div>
  </div>
)
const NothingUploaded = () => (
  <div className="w-full flex justify-center items-center border-t border-b border-gray-030 ">
    <Body2 className="w-full py-6 text-center">작성된 게시글이 없습니다.</Body2>
  </div>
)
export default BoardList;